import { CartBaseController } from './cart_base_controller'

/*
 * Renders the order table.  All products are stored on localStorage, so
 * we just fetch that information and render the cart contents using
 * Liquid.
 */
export default class extends CartBaseController {
  static targets = [ 'cart', 'subtotal', 'itemCount' ]

  async connect () {
    window.addEventListener('order', async event => {
      const products = this.products
      const site = window.site

      this.render({ products, site })
      this.subtotalUpdate()
      this.itemCountUpdate()
      this.subscribe()
    })

    window.dispatchEvent(new Event('order'))
  }

  /*
   * Subscribe to change on the storage to update the cart.
   */
  subscribe () {
    window.addEventListener('storage', async event => {
      if (!event.key?.startsWith('cart:item:')) return

      const products = this.products
      const site = window.site

      this.render({ products, site })
      this.subtotalUpdate()
      this.itemCountUpdate()
    })

    window.addEventListener('cart:subtotal:update', event => {
      this.itemCountUpdate()
      this.subtotalUpdate()
    })
  }

  /*
   * Download the item template and render the order
   */
  render (data = {}) {
    const template = this.data.get('template')

    this.engine.parseAndRender(window.templates[template], data).then(html => this.cartTarget.innerHTML = html)
  }

  /*
   * Updates the subtotal
   *
   * XXX: This also updates the currency
   */
  subtotalUpdate () {
    if (!this.cart) return

    this.subtotalTarget.innerText = this.cart.data.attributes.display_total
  }

  itemCountUpdate () {
    if (!this.hasItemCountTarget) return
    if (!this.cart) return

    this.itemCountTarget.innerText = this.cart.data.attributes.item_count
  }
}
