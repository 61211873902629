import { CartBaseController } from './cart_base_controller'

export default class extends CartBaseController {
  static targets = [ 'order' ]

  async connect () {
    let data

    if (this.storage.cart) {
      const order = this.cart.data.attributes
      const products = this.products
      const site = window.site
      const shipping_address = JSON.parse(this.storage.getItem('shipping_address'))

      this.storage.clear()
      this.storageTemp.clear()

      window.dispatchEvent(new CustomEvent('cart:counter', { detail: { item_count: 0 }}))

      data = { order, products, site, shipping_address }

      this.storage.setItem('confirmation', JSON.stringify(data))
    } else {
      data = JSON.parse(this.storage.getItem('confirmation'))
    }

    this.render(data)
  }

  render (data = {}) {
    fetch(this.element.dataset.template).then(r => r.text()).then(template => {
      this.engine.parseAndRender(template, data).then(html => this.orderTarget.innerHTML = html)
    })
  }
}
