import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    title: String,
    text: String,
    url: String
  }

  async share (event = undefined) {
    event?.preventDefault()
    event?.stopPropagation()

    const title = this.titleValue
    const text = this.textValue
    const url = this.urlValue
    const data = { title, text, url }

    if ('share' in navigator) {
      if (navigator.canShare(data)) {
        navigator.share(data)
      } else {
        console.error('No se puede compartir', data)
      }
    }
  }
}
