import { CartBaseController } from './cart_base_controller'

/*
 * Retrieves shipping methods
 */
export default class extends CartBaseController {
  static targets = [ 'form', 'submit' ]

  connect () {
    window.addEventListener('cart:shipping:methods', async event => {
      const orderToken = this.token
      const site = window.site
      const response = await this.spree.checkout.shippingMethods({ orderToken }, { include: 'shipping_rates' })

      if (response.isFail()) {
        this.handleFailure(response)
        return
      }

      // XXX: We're assuming line items aren't back ordered so there's
      // going to be a single shipping rate for each shipping method.
      //
      // XXX: Actually if there's only going to be a single shipping
      // method, all its rates will be on included so we don't need to
      // filter.
      const shipping_rates = response.success().included.filter(x => x.type == 'shipping_rate')
      const shipping_method = response.success().data[0]

      this.render({ shipping_method, shipping_rates, site })
    })
  }

  /*
   * Download the item template and render the order
   */
  render (data = {}) {
    fetch(this.data.get('template')).then(r => r.text()).then(template => this.engine.parseAndRender(template, data).then(html => this.element.innerHTML = html))
  }

  // Send via API
  async submit (event) {
    event.preventDefault()
    event.stopPropagation()

    this.submitTarget.disabled = true

    const orderToken = this.token
    const selectedInput = Array.from(this.formTarget.elements).find(x => x.checked)
    // XXX: It would be great if we could use a regular form and get
    // this information from the form itself, but we can't pair two
    // values, and we couldn't find a standard way to get form values
    // as an object.
    const response = await window.spree.checkout.orderUpdate({ orderToken }, {
      order: {
        shipments_attributes: [{
          id: selectedInput.dataset.shippingMethodId,
          selected_shipping_rate_id: selectedInput.value
        }]
      }
    })

    if (response.isFail()) {
      this.handleFailure(response)
      return
    }

    this.cart = response

    // Continue to next step
    this.visit(this.data.get('next'))
  }
}
