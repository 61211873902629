import { CartBaseController } from './cart_base_controller'

// TODO: Usar FormData
export default class extends CartBaseController {
  static targets = [ 'form', 'username', 'firstname', 'lastname', 'password', 'passwordConfirmation', 'phone' ]

  connect () {
  }

  async submit (event) {
    // No vamos a mandar la información a ningún lado
    event.preventDefault()

    const username = this.username
    const password = this.password

    this.formDisabled = true

    let response = await this.spree.account.create({
      user: {
        email: username,
        password: password,
        password_confirmation: this.password_confirmation
      }
    })

    if (response.isFail()) {
      this.handleFailure(response)
      this.formDisabled = false
      return
    }

    response = await this.spree.authentication.getToken({ username, password })

    if (response.isFail()) {
      this.handleFailure(response)
      this.formDisabled = false
      return
    }

    const bearerToken = response.success().access_token
    const orderToken = this.token

    // Store bearerToken for later use
    this.bearerToken = bearerToken
    this.email = username

    // Inform Spree to assign the order to this user
    this.spree.sutty.assignOrderOwnership({ bearerToken }, { orderToken })

    response = await this.spree.account.createAddress({ bearerToken }, {
      address: {
        firstname: this.firstname,
        lastname: this.lastname,
        country_iso: 'AR',
        city: 'AMBA',
        state_name: 'B',
        zipcode: '1111',
        address1: 'No informada',
        phone: this.phone
      }
    })

    if (response.isFail()) {
      this.handleFailure(response)
      this.formDisabled = false
      return
    }

    this.notify({ type: 'success', content: site.i18n.alerts.successful_signup })
    this.scrollToNextStep()
  }

  get username () {
    return this.usernameTarget.value.trim()
  }

  // Las contraseñas no se modifican!
  get password () {
    return this.passwordTarget.value
  }

  // XXX: No me gusta el dromedaryCase
  get password_confirmation () {
    return this.passwordConfirmationTarget.value
  }

  get firstname () {
    return this.firstnameTarget.value.trim()
  }

  get lastname () {
    return this.lastnameTarget.value.trim()
  }

  get phone () {
    return this.phoneTarget.value.trim()
  }

  scrollToNextStep () {
    const nextStep = document.querySelector(`#${this.element.dataset.scrollTo}`)

    if (!nextStep) return

    nextStep.scrollIntoView()
  }
}
