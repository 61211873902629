import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    this.eventListener = this._eventListener.bind(this)

    window.addEventListener('cajon', this.eventListener)
  }

  disconnect () {
    window.removeEventListener('cajon', this.eventListener)
  }

  _eventListener (event) {
    if (event.detail.cajon !== this.element.id) return

    if(this[event.detail.state]) this[event.detail.state]()

    window.dispatchEvent(new Event('order'))
  }

  open () {
    this.element.classList.add('show')
    this.element.classList.remove('hide')
  }

  close () {
    this.element.classList.add('hide')
    this.element.classList.remove('show')
  }
}
