import { CartBaseController } from './cart_base_controller'

/*
 * Retrieves payment methods and redirect to external checkouts
 */
export default class extends CartBaseController {
  static targets = [ 'form', 'submit', 'method', 'specialInstructions' ]

  async connect () {
    const orderToken = this.token
    const response = await this.spree.checkout.paymentMethods({ orderToken })

    if (response.isFail()) {
      this.handleFailure(response)
      return
    }

    const payment_methods = response.success().data
    const site = window.site
    const cart = this.cart
    const next = { url: this.data.get('nextUrl') }
    const back = { url: this.data.get('backUrl') }

    this.render({ payment_methods, site, cart, next, back })
  }

  render (data = {}) {
    fetch(this.data.get('template')).then(r => r.text()).then(template => {
      this.engine.parseAndRender(template, data).then(html => {
        this.element.innerHTML = html
        this.formTarget.elements.forEach(p => p.addEventListener('change', e => this.submitTarget.disabled = false))
      })
    })
  }

  async submit (event) {
    event.preventDefault()
    event.stopPropagation()

    this.submitTarget.disabled = true

    // TODO: Find a more natural way to do this
    const payment_method_id = this.formTarget.elements["order[payments_attributes][][payment_method_id]"].value
    const orderToken = this.token
    const special_instructions = this.specialInstructionsTarget.value.trim()

    // XXX: Currently SpreeClient expects us to send payment source
    // attributes as if it were a credit card.
    let response = await this.spree.checkout.orderUpdate({ orderToken },
      {
        order: {
          special_instructions,
          payments_attributes: [{ payment_method_id }]
        }
      })

    if (response.isFail()) {
      this.handleFailure(response)
      return
    }

    this.cart = response

    response = await this.spree.checkout.complete({ orderToken })

    if (response.isFail()) {
      this.handleFailure(response)
      return
    }

    this.cart = response

    const checkoutUrls = await this.spree.sutty.getCheckoutURL({ orderToken })
    let redirectUrl = this.data.get('nextUrl')

    if (checkoutUrls.data.length > 0) redirectUrl = checkoutUrls.data[0]

    window.location = redirectUrl
  }
}
