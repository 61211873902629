import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'selector' ]

  connect () {
  }

  show (event) {
    for (const selector of this.selectorTargets) {
      const elements = document.querySelectorAll(selector.value)

      for (const element of elements) {
        element.classList.toggle('hide')
        element.classList.toggle('show')
        element.classList.toggle('collapsing')
      }
    }
  }
}
