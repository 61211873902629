import BotDetector from 'device-detector-js/dist/parsers/bot'
import { Notifier } from '@airbrake/browser'

window.bot_detector = new BotDetector
const bot = window.bot_detector.parse(navigator.userAgent)

if (!bot) {
  try {
    window.airbrake = new Notifier({
      projectId: window.env.AIRBRAKE_PROJECT_ID,
      projectKey: window.env.AIRBRAKE_PROJECT_KEY,
      host: 'https://panel.sutty.nl'
    })

    console.originalError = console.error
    console.error = (...e) => {
      window.airbrake.notify(e.join(' '))
      return console.originalError(...e)
    }
  } catch (error) {
    console.error('No pudimos cargar Airbrake, ningún error va a ser reportado.', error)
  }
}

import 'core-js/stable'
import 'regenerator-runtime/runtime'

// Turbo acelera la navegación al no tener que recargar todo el JS y CSS
// de la página, con lo que se siente más rápida y "nativa".
//
// Cambiamos de turbolinks a turbo porque turbo soporta la función
// fetch(), que luego es interceptada por el SW para obtener las
// direcciones localmente.
import * as Turbo from "@hotwired/turbo"
Turbo.start()

import { Application } from 'stimulus'
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

import { makeClient } from '@spree/storefront-api-v2-sdk'
import { Sutty } from './endpoints/sutty'
import { loadEvent } from './load_event'

window.spree = makeClient({ host: window.env.SPREE_URL })
window.spree.sutty = new Sutty(window.spree.host)

// Cerrar el menú al cambiar de página
document.addEventListener('turbo:load', event => {
  document.querySelector('#menu-toggler').checked = false

  const cajon = 'cajon'
  const state = 'close'

  window.dispatchEvent(new CustomEvent('cajon', { detail: { cajon, state }}))
})

try {
  window.axe = require('axe-core/axe')
} catch(e) {}

if (window.axe) window.axe.configure({ locale: require('axe-core/locales/es.json') })

document.addEventListener('turbo:load', event => {
  document.querySelectorAll("a[href^='http://'],a[href^='https://'],a[href^='//']").forEach(a => {
    a.rel = "noopener"
    a.target = "_blank"
  })

  if (!window.axe) return

  window.axe.run().then(results => {
    results.violations.forEach(violation => {
      violation.nodes.forEach(node => {
        node.target.forEach(target => {
          document.querySelectorAll(target).forEach(element => {
            element.classList.add('inaccesible')
            element.ariaLabel = node.failureSummary
          })
        })
      })
    })
  })
})
