import { CartBaseController } from './cart_base_controller'

/*
 * Register or login users.
 *
 * It brings shipping and billing addresses from the account.
 */
export default class extends CartBaseController {
  static targets = [ 'form', 'password', 'username' ]

  async submit (event) {
    if (this.passwordTarget.value.trim().length === 0) return

    event.preventDefault()

    const username = this.usernameTarget.value.trim()
    const password = this.passwordTarget.value.trim()

    this.formDisabled = true

    // We can login after registering too
    let response = await this.spree.authentication.getToken({ username, password })

    const site = window.site

    if (response.isFail()) {
      this.notify({ type: 'warning', content: site.i18n.alerts.incorrect_password })
      this.formDisabled = false

      return
    }

    this.notify({ type: 'success', content: site.i18n.alerts.successful_login })

    const bearerToken = response.success().access_token
    const orderToken = this.token

    // Store bearerToken for later use
    this.bearerToken = bearerToken
    this.email = username

    // Inform Spree to assign the order to this user
    this.spree.sutty.assignOrderOwnership({ bearerToken }, { orderToken })

    this.scrollToNextStep()
  }

  scrollToNextStep () {
    const nextStep = document.querySelector(`#${this.element.dataset.scrollTo}`)

    if (!nextStep) return

    nextStep.scrollIntoView()
  }
}
