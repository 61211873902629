import { CartBaseController } from './cart_base_controller'

/*
 * Updates the billing address if needed.
 */
export default class extends CartBaseController {
  static targets = [ 'form', 'sameAsShippingAddress' ]

  connect () {
    this.formTarget.addEventListener('focusout', async event => {
      // Do nothing if the user selected to repeat the shipping address
      if (this.hasSameAsShippingAddressTarget
          && this.sameAsShippingAddressTarget.checked) return
      if (!this.formTarget.checkValidity()) {
        this.formTarget.classList.add('was-validated')
        return
      }

      const orderToken = this.token
      const bill_address_attributes = {}

      // Collect values from inputs
      this.formTarget.elements.forEach(input => bill_address_attributes[this.idFromInputName(input)] = input.value)

      const response = await this.spree.checkout.orderUpdate({ orderToken }, { order: { bill_address_attributes }})

      if (response.isFail()) {
        this.handleFailure(response)
        return
      }

      this.assignBillingAddress()

      this.cart = response
      window.dispatchEvent(new Event('cart:shipping:methods'))
    })

    // Fill form from event
    window.addEventListener('billing_address:update', event => {
      this.sameAsShippingAddressTarget.checked = false

      const inputs = Array.from(this.formTarget.elements)

      for (const attribute in event.detail.data) {
        let id = `billing_address_${attribute}`

        switch (attribute) {
          case 'country_name':
            id = 'billing_address_ignore_country_id'
            break
          case 'state_name':
            id = 'nonexistent'
            break
        }

        const input = inputs.find(x => x.id === id)

        if (!input) continue

        // XXX: Instruct the form to pass the next state name along
        // TODO: This controller shouldn't have to do this
        if (id === 'billing_address_ignore_country_id') {
          input.dataset.selectedState = event.detail.data.state_name
          input.dataset.selectedZipcode = event.detail.data.zipcode
        }

        input.value = event.detail.data[attribute]
        input.dispatchEvent(new Event('change'))
      }
    })

    this.shouldGetShippingRates = false

    // XXX: Recover form state, values are stored when changed, remember
    // to launch ChangeEvent's if you're setting values programatically.
    this.formTarget.elements.forEach(input => {
      const id = `billing_address_${this.idFromInputName(input)}`
      const value = this.storageTemp.getItem(id)

      input.addEventListener('change', event => this.storageTemp.setItem(id, input.value))

      if (!value) return

      input.disabled = false
      input.value = value

      // TODO: This controller shouldn't have to do this
      if (input.id === 'billing_address_ignore_country_id') {
        input.dataset.selectedState = this.storageTemp.getItem('billing_address_billing_address_ignore_state_id')
        input.dataset.selectedZipcode = this.storageTemp.getItem('billing_address_zipcode')
      }

      this.shouldGetShippingRates = true
    })

    // Recalculate shipping rates after the form state has been
    // recovered
    if (!this.shouldGetShippingRates) return

    this.formTarget.dispatchEvent(new Event('focusout'))
  }

  assignBillingAddress () {
    if (!this.bearerToken) return

    const bearerToken = this.bearerToken
    const orderToken = this.token

    this.spree.sutty.assignOrderBillingAddress({ bearerToken }, { orderToken })
  }
}
