import { Controller } from 'stimulus'

import L from 'leaflet'
delete L.Icon.Default.prototype._getIconUrl

L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'public/marker-icon-2x.png',
  iconUrl: 'public/marker-icon.png',
  shadowUrl: 'public/marker-shadow.png',
})

export default class extends Controller {
  static targets = [ 'mapa', 'nodo', 'nodos', 'marker' ]

  connect () {
    window.addEventListener('resize', event => this.map.invalidateSize())

    for (const nodo of this.nodoTargets) {
      const coords = this.coords_from(nodo)

      if (!coords) continue

      const marker = this.marker(coords)
      marker._icon.dataset.element = "#" + nodo.id
      marker._icon.dataset.target = "puntos-entrega.marker shipping-type.marker"

      marker.on('click', event => {
        const hash = event.target._icon.dataset.element
        const nodo = document.querySelector(hash)

        if (!nodo) return

        window.history.pushState({}, '', window.location.pathname + hash)

        nodo.querySelector('[data-action~="puntos-entrega#select"]').click()
      })
    }
  }

  center (coords, marker) {
    this.map.setView(coords, this.map.zoom)
    this.markerTargets.forEach(m => this.toggle_marker(m, m.dataset.element === marker))
  }

  // TODO: Al actualizar a Stimulus 2 convertir en selectedClass
  get selected () {
    if (!this._selected) this._selected = this.element.dataset.selected.split(" ").map(x => x.trim())

    return this._selected
  }

  select (event) {
    event.preventDefault()

    this.nodoTargets.forEach(nodo => nodo.classList.remove(...this.selected))

    const nodo = document.querySelector(event.target.dataset.element)

    if (!nodo) return

    nodo.classList.add(...this.selected)

    if (this.hasNodosTarget) {
      if (nodo.parentElement == this.nodosTarget) {
        nodo.parentElement.scroll(0, nodo.offsetTop)
      } else {
        console.warn('Advertencia: nodo.parentElement no es el contenedor de los nodos. No se pudo scrollear al nodo.')
      }
    }

    this.center(this.coords_from(nodo), event.target.dataset.element)
  }

  /*
   * Obtener coordenadas del dataset de un elemento, si alguna no es
   * válida devuelve undefined
   *
   * @return [Array,undefined]
   */
  coords_from(element) {
    const lat = parseFloat(element.dataset.lat)
    const lng = parseFloat(element.dataset.lng)

    if (isNaN(lat) || isNaN(lng)) return undefined

    return [lat,lng]
  }

  /*
   * Obtener las coordenadas del mapa, por defecto el centro de CABA (!)
   */
  get coords () {
    const c = this.coords_from(this.element)

    return c ? c : [-34.6109,-58.4294]
  }

  get zoom () {
    const z = parseInt(this.element.dataset.zoom)

    return isNaN(z) ? 11 : z
  }

  get map () {
    if (!this._map) {
      this._map = L.map(this.mapaTarget).setView(this.coords, this.zoom)

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
        attribution: '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>'
      }).addTo(this._map)
    }

    return this._map
  }

  get icon () {
    if (!this._icon) {
      this._icon = L.divIcon({
        className: 'fill-gray-medium transition',
        iconSize: [25,41],
        iconAnchor: [12.5,41],
        html: '<svg xmlns="http://www.w3.org/2000/svg" width="25" height="41" viewBox="0 0 6.615 10.848"><path fill="#00aeef" d="M3.307 10.842c0 .002.004.006.004.006s3.304-5.361 3.304-7.123C6.615 1.132 4.94.004 3.307 0 1.674.004 0 1.132 0 3.725c0 1.762 3.305 7.123 3.305 7.123l.002-.006zM2.163 3.577c0-.67.513-1.211 1.146-1.211.634 0 1.147.542 1.147 1.211 0 .67-.515 1.212-1.149 1.212-.631 0-1.144-.542-1.144-1.212z" stroke-width="13.649"/></svg>'
      })
    }

    return this._icon
  }

  marker (coords) {
    return L.marker(coords, { icon: this.icon }).addTo(this.map)
  }

  // TODO: Obtener las clases de la configuración
  toggle_marker (marker, state) {
    if (state) {
      marker.classList.remove('fill-gray-medium')
      marker.classList.add('fill-red')
    } else {
      marker.classList.add('fill-gray-medium')
      marker.classList.remove('fill-red')
    }
  }
}
