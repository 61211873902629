import { CartBaseController } from './cart_base_controller'

export default class extends CartBaseController {
  static targets = [ 'nodo', 'marker', 'form' ]

  connect () {
  }

  async submit (event) {
    // Al enviar el formulario, queremos actualizar la dirección de le
    // usuarie y asignársela a la orden.
    event.preventDefault()

    const site = window.site

    // No hacer nada si no estamos logueades o no hay un nodo
    // seleccionado.
    if (!this.nodo) {
      window.dispatchEvent(
        new CustomEvent('notification', {
          detail: {
            template: 'alert',
            data: {
              type: 'danger',
              content: site.i18n.alerts.no_shipping_location
            }
          }
        })
      )
      return
    }

    const orderToken = this.token
    const bearerToken = this.bearerToken

    if (!orderToken) {
      window.dispatchEvent(
        new CustomEvent('notification', {
          detail: {
            template: 'alert',
            data: {
              type: 'danger',
              content: site.i18n.alerts.empty_cart
            }
          }
        })
      )
      return
    }

    if (!bearerToken) {
      window.dispatchEvent(
        new CustomEvent('notification', {
          detail: {
            template: 'alert',
            data: {
              type: 'danger',
              content: site.i18n.alerts.not_logged_in
            }
          }
        })
      )
      return
    }

    this.formDisabled = true

    const email = this.email
    const firstAddress = await this.firstAddress(bearerToken)

    if (!firstAddress) {
      this.formDisabled = false
      console.error('No hay dirección que actualizar')
      return
    }

    const address = firstAddress.attributes
    const id = firstAddress.id
    // Actualizar los valores de la dirección
    for (const name in this.address) {
      address[name] = this.address[name]
    }

    // La dirección 2 tiene la fecha y horario de entrega
    if (this.element.dataset.extraAddress2)
      address.address2 = this.element.dataset.extraAddress2 + ' (' + address.address2 + ')'

    // Actualiza la direccion de le usuarie
    const updateAddress = await this.updateAddress(bearerToken, id, address)

    if (!updateAddress) {
      this.formDisabled = false
      console.error('No se pudo actualizar la dirección')
      return
    }

    // Asigna la dirección a la compra.  La dirección se repite pero
    // número de teléfono, nombre propio, etc. no.  En un principio
    // pensábamos que se podía enviar solo el ID de la dirección pero
    // nos da un error de atributos vacíos.
    const ship_address_attributes = address
    const bill_address_attributes = ship_address_attributes

    this.storage.setItem('shipping_address', JSON.stringify(address))

    let response = await this.spree.checkout.orderUpdate({ orderToken }, { order: { email, ship_address_attributes, bill_address_attributes }})

    if (response.isFail()) {
      this.handleFailure(response)
      this.formDisabled = false
      return
    }

    this.assignShippingAddress()

    const shippingMethods = await this.shippingMethods(orderToken)

    if (!shippingMethods) {
      this.formDisabled = false
      console.error('No se pudieron obtener métodos de envío')
      return
    }

    // TODO: Estamos asumiendo que la tienda está bien configurada y
    // siempre va a devolver un método de envío.
    const shippingRate = shippingMethods.included.filter(x => x.type === 'shipping_rate').find(x => x.attributes.code === "punto_de_entrega")
    const shippingMethod = shippingMethods.data[0]

    response = await this.spree.checkout.orderUpdate({ orderToken }, {
      order: {
        shipments_attributes: [{
          id: shippingMethod.id,
          selected_shipping_rate_id: shippingRate.id
        }]
      }
    })

    if (response.isFail()) {
      this.handleFailure(response)
      this.formDisabled = false
      return
    }

    this.cart = response
    this.formDisabled = false
    if (this.element.dataset.next) this.visit(this.element.dataset.next)
  }

  select (event) {
    event.preventDefault()
    if (!this.hasFormTarget) return

    this.nodo = document.querySelector(event.target.dataset.element)
    this.update_address()
  }

  // Obtiene la dirección del punto de entrega a partir de sus
  // metadatos.
  get address () {
    return {
      city: this.nodo_prop('addressLocality'),
      address1: this.nodo_prop('streetAddress'),
      address2: this.nodo_prop('openingHours'),
      company: this.nodo_prop('name')
    }
  }

  get nodo () {
    return this._nodo
  }

  set nodo (element) {
    this._nodo = element
  }

  /*
   * Obtener coordenadas del dataset de un elemento, si alguna no es
   * válida devuelve undefined
   *
   * @return [Array,undefined]
   */
  coords_from(element) {
    const lat = parseFloat(element.dataset.lat)
    const lng = parseFloat(element.dataset.lng)

    if (isNaN(lat) || isNaN(lng)) return undefined

    return [lat,lng]
  }

  nodo_prop (name) {
    if (!this.nodo) return ''

    const prop = this.nodo.querySelector(`[itemprop="${name}"]`)
    return (prop ? prop.innerText.trim() : '')
  }

  // XXX: Los valores ocultos no nos sirven de nada si los vamos a tomar
  // del nodo actual siempre
  update_address () {
    const address = this.address
    const elements = Array.from(this.formTarget.elements)

    for (const name in address) {
      const input = elements.find(x => x.name == name)

      if (!input) continue

      input.value = address[name]
    }
  }
}
