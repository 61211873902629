import { CartBaseController } from './cart_base_controller'

/*
 * Updates the shipping and billing addresses
 */
export default class extends CartBaseController {
  static targets = [ 'form' ]

  connect () {
    this.formTarget.addEventListener('formdata', event => this.processShippingAddress(event.formData))
  }

  submit (event) {
    event.preventDefault()

    // FormDataEvent es muy reciente
    if (window.FormDataEvent) {
      // Esto lanza el evento formdata en el formulario
      new FormData(event.target)
    } else {
      // Fallback
      this.processShippingAddress(new FormData(event.target))
    }
  }

  async processShippingAddress (formData) {
    const site = window.site

    const email = this.email
    const orderToken = this.token
    const bearerToken = this.bearerToken

    if (!orderToken) {
      window.dispatchEvent(
        new CustomEvent('notification', {
          detail: {
            template: 'alert',
            data: {
              type: 'danger',
              content: site.i18n.alerts.empty_cart
            }
          }
        })
      )
      return
    }

    if (!bearerToken) {
      window.dispatchEvent(
        new CustomEvent('notification', {
          detail: {
            template: 'alert',
            data: {
              type: 'danger',
              content: site.i18n.alerts.not_logged_in
            }
          }
        })
      )
      return
    }

    this.formDisabled = true

    const firstAddress = await this.firstAddress(bearerToken)

    if (!firstAddress) {
      this.formDisabled = false
      console.error('No hay dirección que actualizar')
      return
    }

    const address = firstAddress.attributes
    const id = firstAddress.id

    for (const field of formData) {
      address[field[0]] = field[1]
    }

    const updateAddress = await this.updateAddress(bearerToken, id, address)

    if (!updateAddress) {
      this.formDisabled = false
      console.error('No se pudo actualizar la dirección')
      return
    }

    const ship_address_attributes = address
    const bill_address_attributes = ship_address_attributes

    this.storage.setItem('shipping_address', JSON.stringify(address))

    let response = await this.spree.checkout.orderUpdate({ orderToken }, { order: { email, ship_address_attributes, bill_address_attributes }})

    if (response.isFail()) {
      this.handleFailure(response)
      return
    }

    this.assignShippingAddress()

    const shippingMethods = await this.shippingMethods(orderToken)

    if (!shippingMethods) {
      this.formDisabled = false
      console.error('No se pudieron obtener métodos de envío')
      return
    }

    const shippingRate = shippingMethods.included.filter(x => x.type == 'shipping_rate').find(x => x.attributes.code === "envio_a_domicilio")
    const shippingMethod = shippingMethods.data[0]

    response = await this.spree.checkout.orderUpdate({ orderToken }, {
      order: {
        shipments_attributes: [{
          id: shippingMethod.id,
          selected_shipping_rate_id: shippingRate.id
        }]
      }
    })

    if (response.isFail()) {
      this.handleFailure(response)
      this.formDisabled = false
      return
    }

    this.cart = response
    this.formDisabled = false
    if (this.element.dataset.next) this.visit(this.element.dataset.next)
  }

  // Queremos poder ir verificando los campos a medida que les usuaries
  // entran y salen, si no estuvieron no esta bueno decirles que están
  // equivocades antes del hecho.
  validity (event) {
    if (event.target.validity.valid) {
      event.target.classList.remove('is-invalid')
      event.target.classList.add('is-valid')
    } else {
      event.target.classList.add('is-invalid')
      event.target.classList.remove('is-valid')
    }
  }
}
